@import '../../scss/libs/bootstrap/variables';
@import '../../scss/settings';
@import '../../scss/functions';
@import 'bootstrap/scss/mixins/breakpoints';

#footer {
    padding: rem-calc(20) 0 0 0;
    border-top: 1px solid $light-gray;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(20) 0 0 0;
    }

    .cell {
        margin-bottom: rem-calc(16);

        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(10);
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(20);
        }
    }

    .callcentrum {
        color: $dark-purple;
        display: inline-block;
        line-height: rem-calc(60);
        font-size: rem-calc(14);

        @media screen and (max-width: 1024px) {
            line-height: rem-calc(20);
        }

        @media screen and (max-width: 768px) {
            padding-bottom: 30px;
        }

        a {
            text-transform: none;
            color: $dark-purple;
            font-size: rem-calc(16);
        }
    }
}

.footer {
    &__link {
        font-size: 1rem;
        color: $medium-gray;

        &:hover {
            text-decoration: underline;
            color: $medium-gray;
        }
    }
}
