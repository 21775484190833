@use 'sass:math';

@font-face {
    font-family: 'Monserrat';
    src: url('../fonts/montserrat-medium-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/montserrat-medium-webfont.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/montserrat-medium-webfont.woff') format('woff'),
        /* Pretty Modern Browsers */ url('../fonts/montserrat-medium-webfont.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/montserrat-medium-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Monserrat';
    src: url('../fonts/montserrat-bold-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/montserrat-bold-webfont.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/montserrat-bold-webfont.woff') format('woff'),
        /* Pretty Modern Browsers */ url('../fonts/montserrat-bold-webfont.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/montserrat-bold-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Monserrat';
    src: url('../fonts/montserrat-italic-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/montserrat-italic-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/montserrat-italic-webfont.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/montserrat-italic-webfont.woff') format('woff'),
        /* Pretty Modern Browsers */ url('../fonts/montserrat-italic-webfont.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/montserrat-italic-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Monserrat';
    src: url('../fonts/montserrat-bolditalic-webfont.eot'); /* IE9 Compat Modes */
    src: url('../fonts/montserrat-bolditalic-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/montserrat-bolditalic-webfont.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/montserrat-bolditalic-webfont.woff') format('woff'),
        /* Pretty Modern Browsers */ url('../fonts/montserrat-bolditalic-webfont.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts/montserrat-bolditalic-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

// 1. Global
// ---------
$global-font-size-px: 14px;
$global-font-size: rem-calc(14);
$global-width: rem-calc(1180);
$global-lineheight: 1.5;

$primary-color: #82ae33;
$secondary-color: #f6c778;
$success-color: #3adb76;
$warning-color: #f6c778;
$alert-color: #c32d2c;
$info-color: #81007f;

$light-primary: #69b6a5;
$dark-primary: #3b9481;
$lighter-gray: #f8f8f8;
$light-gray: #f2f2f2;
$form-gray: #e3e3e3;
$placeholder-gray: #b5bac2;
$medium-gray: #8c9199;
$dark-gray: #585a5d;
$black: #37292b;

// New Design
$dark-purple: #81007f;
$darker-purple: #5a0058;
$darker-orange: #e18c18;
$dark-orange: #ff9f1b;
$soft-orange: #ffbb32;
$cc-green-soft: #98c052;
$cc-green: #82ae33;
$cc-green-darker: #7da92f;
$red: #b81a1a;
$dark-red: #c32d2c;
$darker-red: #9e1d1c;
$lighter-black: #3b3b3b;
// !New Design

$white: #ffffff;
$body-background: $white;
$body-font-color: $dark-gray;
$body-font-family: 'Monserrat', Helvetica, Roboto, Arial, sans-serif;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: rem-calc(10);
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;

// Forms

$input-color: $black;
$input-font-family: inherit;
$input-font-size: rem-calc(16);
$input-font-weight: $global-weight-normal;
$input-line-height: $global-lineheight;
$input-border: 1px solid $medium-gray;
$form-input-border-bottom: 6px;
